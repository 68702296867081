import Contact from "contacts/Contact";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import Globals from "types/Globals";
import GlobalsContext from "types/GlobalsContext";

type AppProps = {
  globals: Globals;
};

export function initialAppProps(): AppProps {
  let contacts = [
    { name: "Joe", phone: "123" },
    { name: "Jane", phone: "456" },
  ];
  return {
    globals: {
      contacts,
      addContact: (c: Contact) => {
        contacts.push(c);
      },
    },
  };
}

function App(props: AppProps) {
  return (
    <div className="App">
      <h1>
        <Link to="/contacts">Contacts</Link>
      </h1>

      <GlobalsContext.Provider value={props.globals}>
        <Outlet />
      </GlobalsContext.Provider>

      <Link to="/contacts/add">Add contact</Link>
    </div>
  );
}

export default App;
