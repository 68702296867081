import React from "react";
import Globals from "types/Globals";

export const GlobalsContext: React.Context<Globals | null> =
  React.createContext<Globals | null>(null);

GlobalsContext.displayName = "Globals";

export function useGlobals() {
  return React.useContext(GlobalsContext);
}

export default GlobalsContext;
