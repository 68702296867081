import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, Routes, Route, useParams } from "react-router-dom";
import App, { initialAppProps } from "./App.js";
import ContactList from "contacts/pages/ContactList";
import ContactView from "contacts/pages/ContactView";
import ContactAdd from "contacts/pages/ContactAdd";

const container = document.getElementById("root");
const root = createRoot(container!);
const props = initialAppProps();
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<App {...props} />}>
          <Route path="contacts">
            <Route index={true} element={<ContactList />} />
            <Route path=":id" element={<ContactView />} />
            <Route path="add" element={<ContactAdd />} />
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);
