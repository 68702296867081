import React from "react";
import Contact from "contacts/Contact";
import InnerContactView from "contacts/ContactView";
import { useParams } from "react-router-dom";
import { useGlobals } from "types/GlobalsContext";

export function ContactView() {
  let { id } = useParams();
  let { contacts } = useGlobals()!;
  let contact = contacts[parseInt(id!, 10)]!;
  return <InnerContactView contact={contact} />;
}

export default ContactView;
