import React from "react";
import Contact from "contacts/Contact";
import InnerContactAdd from "contacts/ContactAdd";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobals } from "types/GlobalsContext";

export function ContactAdd() {
  let { id } = useParams();
  let { addContact } = useGlobals()!;
  let nav = useNavigate();
  return (
    <InnerContactAdd
      onAdd={(c: Contact) => {
        addContact(c);
        nav("/contacts");
      }}
    />
  );
}

export default ContactAdd;
