import React from "react";
import Contact from "contacts/Contact";
import { Link } from "react-router-dom";

type ContactListProps = {
  contacts: Contact[];
};

function ContactList(props: ContactListProps) {
  let { contacts } = props;
  return (
    <div>
      <ul>
        {contacts.map((c, i) => {
          return (
            <li key={i}>
              <Link to={`/contacts/${i}`}>{c.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ContactList;
