import React from "react";
import InnerContactList from "contacts/ContactList";
import { useGlobals } from "types/GlobalsContext";

export function ContactList() {
  let { contacts } = useGlobals()!;
  return <InnerContactList contacts={contacts} />;
}

export default ContactList;
