import Contact from "./Contact";
import React from "react";
import { Link, useParams } from "react-router-dom";

type ContactViewProps = {
  contact: Contact;
};

export function ContactView(props: ContactViewProps) {
  let { contact } = props;
  return (
    <div>
      <h3>{contact.name}</h3>
      <dl>
        <dt>Phone</dt>
        <dd>{contact.phone}</dd>
      </dl>
      <Link to="/contacts">Back</Link>
    </div>
  );
}

export default ContactView;
