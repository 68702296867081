import React, { FormEvent, useState } from "react";
import Contact from "./Contact";

type ContactAddProps = {
  onAdd: (c: Contact) => void;
};

export function ContactAdd(props: ContactAddProps) {
  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  const onSubmit = function (e: FormEvent<HTMLFormElement>) {
    let c: Contact = {
      name,
      phone,
    };
    props.onAdd(c);
    e.preventDefault();
    return false;
  };
  return (
    <form onSubmit={onSubmit}>
      <label>
        Name
        <input
          type="text"
          required
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Phone
        <input
          type="phone"
          required
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
      </label>
      <br />
      <input type="submit" value="Add" />
    </form>
  );
}

export default ContactAdd;
